$font-url: '/fonts';
@import 'component-core.scss';

.search-packages-result {
  position: relative;
  width: 100%;

  ul {
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f1efef;
    border: 2px solid #ccc;

    li {
      padding: 7px 10px;
      cursor: pointer;

      &:hover {
        background-color: #e6f7ff;
      }
    }
  }
}
