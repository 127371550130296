$font-url: '/fonts';
@import 'component-core.scss';

.package_pacing_heading {
  display: flex;
  justify-content: center;
  width: 360px;
}

.package-pacing-chart {
  margin-right: 50px;
  height: 225px;
  max-width: 400px;

  &__content {
    margin: 52px 0 0 40px;
    padding: 10px 10px 10px 10px;
    background-color: #e8e8e8;
    max-width: 300px;

    &--outdated {
      // text-align: center;
      // vertical-align: center;
      background-color: #f04646;
      color: black;
      width: 206px;
      height: 1.2em;
      display: flex;
      justify-content: center; /* align horizontal */
      align-items: center;
    }
    p {
      color: #9a9a9a;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-top: 10px;
        .dot {
          position: relative;
          top: -1px;
          display: inline-block;
          width: 7px;
          height: 7px;
        }

        .light-blue {
          background-color: #a2bbfb;
        }

        .dark-blue {
          background-color: #004fc2;
        }

        .grey {
          background-color: #cacccb;
        }

        .yellow {
          background-color: #fdd442;
        }
      }
    }
  }
}
