$font-url: '/fonts';
@import 'component-core.scss';

.chart-header {
  display: flex;
  justify-content: center;
}

.top-chart-container {
  position: relative;
  top: -54px;

  .time-frame {
    display: flex;
    align-items: center;
    position: absolute;

    .time-frame-select {
      margin-left: 8px;
      width: 150px;
    }
  }
}

.bottom-chart-container {
  position: relative;
  top: -60px;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 16px 0;

  .chart-selection {
    width: 150px;
    margin-bottom: 8px;
  }

  .chart-area {
    width: 100%;
    height: 225px;
  }
}
