$font-url: '/fonts';
@import 'component-core.scss';

.bulk-edit-page {
  .left-content,
  .right-content {
    width: 50%;
  }

  .left-content {
    .stepped-progress-bar {
      padding: 0 60px;
    }
    .upload-section {
      span {
        margin-right: 20px;
      }

      label {
        background-color: var(--primary1);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 1rem;
        border: 1px solid var(--primary1);

        &.disabled {
          background-color: transparent;
          color: var(--disabled-text);
          border: 1px solid gray;
          cursor: progress;
        }
      }

      #file-chosen {
        margin-left: 10px;
      }

      .error {
        color: rgb(240, 80, 63);
        font-size: 16px;
        text-align: left;
        height: 16px;
      }
    }

    .download-disabled {
      pointer-events: none;
      color: var(--disabled-text);
    }

    .report {
      fieldset {
        padding: 20px;
        width: 40%;

        legend {
          font-size: 16px;
          font-weight: bold;
          padding: 0 10px;
        }
      }
    }
  }
}
