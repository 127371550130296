$font-url: '/fonts';
@import 'component-core.scss';

.package-setting {
	.package-name {
		display: inline-block;
		cursor: pointer;
		margin: 20px 0;

		&:hover {
			.glyph-adgroups-edit {
				display: inline;
			}
		}

		h1 {
			display: inline;
		}

		.glyph-adgroups-edit {
			display: none;
			font-size: 30px;
			padding: 0 10px;

			&::before {
				vertical-align: initial;
			}
		}

		&--edit {
			display: flex;
			margin: 20px 0;

			.package-name-textbox {
				min-width: 400px;
				width: auto;

				input {
					font-size: 36px;
					height: 50px;
				}
			}

			.btn-group {
				margin-left: 15px;

				button {
					margin-right: 5px;
					height: 50px;
				}
			}
		}
	}

	.top {
		display: flex;
		align-items: center;

		h2 {
			margin-right: 40px;
		}

		button {
			&:first-child {
				margin-right: 20px;
			}
		}
	}

	.assign-campaign {
		display: flex;
		margin-top: 40px;

		.left,
		.right {
			width: 30%;

			.display-box {
				height: 400px;
				border: 1px solid var(--color-input-border);
				border-top: none;
				overflow: scroll;

				ul {
					margin: 0;
					padding: 0;
					list-style: none;

					li {
						&.child {
							padding: 7px;
							cursor: pointer;

							&:first-child {
								margin-top: 7px;
							}

							&:hover {
								background: var(--primary1-tint4);
							}

							input {
								width: 15px;
								height: 15px;
							}

							label {
								position: relative;
								padding-left: 7px;
								top: -2px;
							}
						}
					}
				}
			}

			legend {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				padding: 0 10px;
			}

			button {
				margin-top: 20px;
			}
		}

		.left {
			margin-right: 100px;

			.btn-assign {
				float: right;
			}
		}
	}
}
