$font-url: '/fonts';
@import 'component-core.scss';

// global style add here
.wrapper {
  width: 95%;
  height: calc(100vh - (127px));
  margin: auto;
  padding: 0px 0;
}

h1 {
  font-size: 40px;
}

.performanceHeader {
  height: 46px;
}

.mb-30 {
  margin-bottom: 30px;
}

.my-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.col-name {
  cursor: pointer;
  color: #666767;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.error {
  color: var(--color-validation-error-text);
  line-height: 11px;
  font-size: 12px;
  margin: 10px 0;
  text-align: center;

  &.text-left {
    text-align: left;
  }
}

.disabled-link {
  pointer-events: none;
  color: unset;
}

// **********************************************************************************
// **************************************Table***************************************
// **********************************************************************************

table {
  width: 100%;
  margin-top: 30px;
  border: 1px solid var(--color-input-border);

  th {
    text-align: left;
    padding: 10px;
    background: var(--primary1-light);
  }

  td {
    padding: 7px;
    cursor: pointer;

    &:hover {
      background: var(--primary1-tint4);
    }
  }
}

#react-confirm-alert {
  h1 {
    font-size: 24px;
    text-align: center;
  }

  .react-confirm-alert-button-group {
    button {
      height: 32px;
      padding: 0 16px;
      box-shadow: var(--box-shadow);
      background: var(--primary1);
      width: 50%;
      text-transform: uppercase;
    }
  }
}

// **********************************************************************************
// **********************************Header******************************************
// **********************************************************************************
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary1);
  padding: 15px 20px;
}

// **********************************************************************************
// **********************************Home Page***************************************
// **********************************************************************************
.home-page {
  display: flex;

  .left-content {
    width: 320px;

    table {
      width: 100%;
      margin-top: 30px;
      border: 1px solid var(--color-input-border);
      background-color: #fff;

      th {
        text-align: left;
        padding: 10px;
        background: var(--primary1-light);
      }

      td {
        padding: 7px;
        cursor: pointer;

        &:hover {
          background: var(--primary1-tint4);
        }
      }
    }
  }

  .right-content {
    width: calc(100vw - (320px));
    padding: 0 50px;

    .group-btn {
      display: flex;
      justify-content: space-between;
    }

    fieldset {
      padding: 10px;
      margin-top: -27px;
      height: fit-content;

      legend {
        padding: 0 10px;
      }
    }

    .datatable {
      margin-top: 30px;
    }
  }
}

// **********************************************************************************
// *******************************Package Setting Page*******************************
// **********************************************************************************

.package-setting {
  .package-name {
    display: inline-block;
    cursor: pointer;
    margin: 20px 0;

    &:hover {
      .glyph-adgroups-edit {
        display: inline;
      }
    }

    h1 {
      display: inline;
    }

    .glyph-adgroups-edit {
      display: none;
      font-size: 30px;
      padding: 0 10px;

      &::before {
        vertical-align: initial;
      }
    }

    &--edit {
      display: flex;
      margin: 20px 0;

      .package-name-textbox {
        min-width: 400px;
        width: auto;

        input {
          font-size: 36px;
          height: 50px;
        }
      }

      .btn-group {
        margin-left: 15px;

        button {
          margin-right: 5px;
          height: 50px;
        }
      }
    }
  }

  .top {
    display: flex;
    align-items: center;

    h2 {
      margin-right: 40px;
    }

    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .assign-campaign {
    display: flex;
    margin-top: 40px;

    .left,
    .right {
      width: 30%;

      .display-box {
        height: 400px;
        border: 1px solid var(--color-input-border);
        border-top: none;
        overflow: scroll;

        .child {
          padding: 7px;
          cursor: pointer;

          &:first-child {
            margin-top: 7px;
          }

          &:hover {
            background: var(--primary1-tint4);
          }
        }
      }

      legend {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        padding: 0 10px;
      }

      button {
        margin-top: 20px;
      }
    }

    .left {
      margin-right: 100px;

      .btn-assign {
        float: right;
      }
    }
  }
}
