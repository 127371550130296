$font-url: '/fonts';
@import 'component-core.scss';

.spin-container {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);

  .loading-dots {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 2;

    circle {
      fill: var(--primary1);
    }
  }
}


