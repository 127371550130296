$font-url: '/fonts';
@import 'component-core.scss';

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.package-performance-detail-page {
  position: relative;

  .top {
    display: flex;
    height: 600px;

    h3 {
      font-size: 1.5rem;
      margin-left: 16px;
    }

    .left-content {
      max-width: 450px;
      width: 450px;
      align-self: stretch;
      position: relative;

      .button-export {
        margin-bottom: 36px;
        position: absolute;
        bottom: 0;
      }
    }

    .right-content {
      width: calc(100vw - (450px));
    }
  }
  .camp-table {
    position: relative;
    top: 4px;
  }
  .history-toggle-container {
    position: absolute;
    top: 578px;
    left: 425px;
    margin-top: 2px;
    .hist-title {
      position: relative;
      top: -20px;
      left: 40px;
    }
  }
  .lt-label-container {
    position: absolute;
    top: 578px;
    margin-top: 2px;
  }
  .export-btn {
    position: absolute;
    top: 568px;
  }
}
