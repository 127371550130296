$font-url: '/fonts';
@import 'component-core.scss';

.home-page {
  display: flex;

  .left-content {
    width: 380px;

    table {
      width: 100%;
      margin-top: 30px;
      border: 1px solid var(--color-input-border);
      background-color: #fff;

      th {
        text-align: left;
        padding: 10px;
        background: var(--primary1-light);
      }

      td {
        padding: 7px;
        cursor: pointer;

        &:hover {
          background: var(--primary1-tint4);
        }
      }
    }
  }

  .right-content {
    width: calc(100vw - (380px));
    padding: 0 50px;

    .btn-bulk-export {
      display: inline-block;
      margin-right: 8px;
    }

    .group-btn {
      display: flex;
      justify-content: space-between;

      &--report-date {
        margin-top: -30px;

        p {
          margin-top: 0;
          text-align: center;
        }
        &--dt {
          width: 232px;
          margin-left: 80px;
          &--outdated {
            background-color: rgb(255, 0, 0);
            width: 230px;
            margin-left: 80px;
          }
        }
      }
    }

    fieldset {
      padding: 10px;
      margin-top: -27px;
      height: fit-content;

      legend {
        padding: 0 10px;
      }
    }

    .datatable {
      margin-top: 10px;
    }

    .advertiser-list {
      position: relative;
      margin-top: 0px;
      text-align: right;

      &--button {
        position: relative;
        padding: 5px 30px 5px 20px;
        border: none;
        box-shadow: none;
        border-radius: 5px;
        cursor: pointer;
        color: #67a7dc;
        background-color: #c0eaff;

        &:focus {
          outline: none;
        }

        .arrow {
          position: absolute;
          top: 10px;
          right: 10px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
        .arrow-up {
          border-bottom: 5px solid #67a7dc;
        }

        .arrow-down {
          border-top: 5px solid #67a7dc;
        }
      }

      ul {
        position: absolute;
        padding: 15px;
        top: 14px;
        right: 0;
        z-index: 10;
        text-align: left;
        background-color: #fff;
        border: 1px solid #ddd;

        li {
          padding: 5px 0;
          list-style: none;

          label {
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }

      .is-show {
        display: block;
      }

      .is-hide {
        display: none;
      }
    }
  }
}
