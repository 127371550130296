$font-url: '/fonts';
@import 'component-core.scss';

.login-page {
  overflow: hidden;
  .login-form {
    width: 40%;
    margin: auto;
    padding: 100px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .logo {
      text-align: center;
      margin-bottom: 50px;
    }

    .text-control {
      margin-top: 5px;
    }

    #id-btn-login {
      width: 100%;
    }
  }
}
